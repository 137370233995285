import { GetGoodsExtendInfo, GetGoodsInfo, getGoodsRelatedBySku, GetReGoodsByCode,GetAllSmallImg } from "@/api/goods/goods";
import { AddToCarts, DeleteGoods2Carts, GetCartInforBySku } from "@/api/carts/carts";
import { getCredentialTypeBySku } from "@/api/goods/credentialType";

import { addColect, hasJoinCollect } from "@/api/goods/collect";

export default {
  components: {},
  data() {
    return {
      code: "",
      num: 1,
      min: 1,
      step: 1,
      goodsInfo: {},
      goodsExtend: {},
      reGoodsList: [],
      credentialList: [],
      goodsRelated: [],
      theGoodsCartInfo: {}, //购物车详情信息
      srcList:[],//图片列表
      smallImgList:[],//侧边小图片列表
      initIndexImg:0,//主图显示的位置
      showVideo: {
        show: false,
        src: "",
        showList: false,
      },
      isJoin: false, //是否显示
      lang: "",
    };
  },
  watch: {
    goodsInfo() {
      this.getReGoodsList();
      //获取这个商品是否有加入到收藏
      this.ifJoinCollect();
    },
  },
  created() {
    this.getLang()
    this.getCode();
    this.getInfomation();
    // this.credentialType();
    this.getGoodsRelatedBySku();
    this.GetGoodsExtend(this.code);
    this.getCartInfo(this.code);
    this.hasVideo();
    setTimeout(() => {
      this.getAllSmallImg();
    }, 1000);
  },

  methods: {
    hasVideo() {
      let url="http://hwimg.xmvogue.com/video/" + this.code + ".mp4?";
      this.showVideo.src=url;
      var video=document.createElement("video");

      video.src=url;
      //不同浏览器情况不同，这里判断在该浏览器是否可以播放
      video.oncanplaythrough=() => {
        this.showVideo.show=true;
        this.showVideo.showList=true;
      };
    },
       // 获取当前的语言环境
   getLang() {
    // if (this.$i18n.locale == "zh-CN") {
    // 	this.lang = this.$i18n.locale
    // } else {
    // }
    this.lang = this.$i18n.locale;
 },
    //判断是否已经加入到购物车
    ifJoinCollect() {
      hasJoinCollect(this.code).then((res) => {
        let resData=res.data.data;
        this.isJoin=resData.id > 0;
      });
    },
    //添加到收藏
    add2Collect() {
      let postData={
        "sku": this.code,
      };
      addColect(postData).then((res) => {
        this.$message.success(this.$t("Successfully joined the collection"));
        this.isJoin=true;
      }).catch((err) => {
        let msg="";
        if (err.response.data.message === "已经加入收藏了") {
          msg=this.$t("Has been added to the collection");
        }
        this.$message.error(msg);
      });
    },

    //切换显示图片或者视频
    videoImgShow(id) {
      if (id == 0) {
        this.showVideo.show=true;
      }
      if (id == 1) {
        this.showVideo.show=false;
      }
    },
    //删除购物车
    delCartGoods() {
      if (this.code !== "") {
        DeleteGoods2Carts(this.code).then((rs) => {
          this.getCartInfo(this.code);
          this.$emit("initCart");
        });
      }
    },
    //根据sku获取购物车的详情信息
    getCartInfo(sku) {
      GetCartInforBySku(sku).then((res) => {
        this.theGoodsCartInfo=res.data.data;
      });
    },
    //复制商品条码
    onCopy: function() {
      this.$message.success("Product barcode copied to clipboard");
    },
    onError: function() {
      this.$message.success("Copy failed");
    },
    //获取商品扩展
    GetGoodsExtend(sku) {
      GetGoodsExtendInfo(sku).then((res) => {
        this.goodsExtend=res.data.data;
        if (this.$i18n.locale === "zh-CN") {
          this.goodsExtend.show_features=this.goodsExtend.features;
          this.goodsExtend.show_caution=this.goodsExtend.caution;
          this.goodsExtend.show_notes=this.goodsExtend.notes;
        } else {
          this.goodsExtend.show_features=this.goodsExtend.features_en;
          this.goodsExtend.show_caution=this.goodsExtend.caution_en;
          this.goodsExtend.show_notes=this.goodsExtend.notes_en;
        }
      });
    },
    //推荐点击
    clickRelated(row) {
      this.gotoSku(row.sku_list);
      setTimeout(() => {
        this.getAllSmallImg();
      }, 500);
    },
    gotoSku(code) {
      this.$router.push({
        path: "/web/goodsDetail",
        query: {
          id: code,
        },
      });
      this.getCode();
      this.getInfomation();
      // this.credentialType();
      this.getGoodsRelatedBySku();
      this.GetGoodsExtend(this.code);
      this.getCartInfo(this.code);
      this.hasVideo();
      // window.open(href);
    },
    //获取关联商品
    getGoodsRelatedBySku() {
      getGoodsRelatedBySku(this.code).then((res) => {
        this.goodsRelated=res.data.data;
      });
    },
    //获取认证文件
    credentialType() {
      getCredentialTypeBySku(this.code).then((res) => {
        this.credentialList=res.data.data;
      });
    },
    //推荐列表点击
    reClick(row) {
      this.gotoSku(row.item_no);
    },
    getReGoodsList() {
      GetReGoodsByCode(this.goodsInfo.item_no, 4).then((res) => {
        this.reGoodsList=res.data.data;
      });
    },
    //点击加入购物车
    clickCart() {
      if (this.goodsInfo.stock_num > this.goodsInfo.purchase_spec && this.num % this.goodsInfo.purchase_spec !== 0) {
        this.$message.error(
          this.$t("Please change in multiples of specifications"),
        );
        this.$nextTick(() => {
          this.num=this.goodsInfo.purchase_spec;
        });
      } else {
        AddToCarts(this.goodsInfo.item_no, this.num).then(() => {
          this.$message.success(this.$t("Successfully added to shopping cart"));
          this.getCartInfo(this.code);
          this.$emit("initCart");
        }).catch((err) => {
          if (err.response) {
            let resData=err.response.data;
            switch (resData.message) {
              case "超过库存数量":
                if (this.$i18n.locale === "zh-CN") {
                  this.$message.error("超过库存数量.");
                } else {
                  this.$message.error("Insufficient product inventory.");
                }
                break;
            }
          }
        });
      }
    },
    //获取code
    getCode() {
      var query=this.$route.query;
      this.code=query.id;
    },
    getInfomation() {
      if (this.code) {
        GetGoodsInfo(this.code).then((res) => {
          this.goodsInfo=res.data.data;
          //.toFixed(2)
          if (this.$i18n.locale === "zh-CN") {
            this.goodsInfo.goodsName=this.goodsInfo.item_name;
          } else {
            this.goodsInfo.goodsName=this.goodsInfo.item_en;
          }
          this.num=this.goodsInfo.stock_num >= this.goodsInfo.purchase_spec ? this.goodsInfo.purchase_spec : 1;
          this.min=this.goodsInfo.stock_num >= this.goodsInfo.purchase_spec ? this.goodsInfo.purchase_spec : 1;
          this.step=this.goodsInfo.stock_num >= this.goodsInfo.purchase_spec ? this.goodsInfo.purchase_spec : 1;

          //console.log(this.goodsInfo.gt_cls_no)
          this.credentialList=this.goodsInfo.file_type_icon_list.split(",");

        });
      }
    },

    /* 获取所有图片 */
    getAllSmallImg(){
      this.smallImgList = []
      GetAllSmallImg(this.goodsInfo.id).then((res)=>{
        var smallImg = res.data.data
        // 先把大图放第1
        this.smallImgList.push("http://hwimg.xmvogue.com/thumb/"+this.code+".jpg?x-oss-process=style/800")
        for (const key in smallImg) {
          this.smallImgList.push(smallImg[key].url)
        }
      })
    },

    /* 切换当前图片 */
    changeIndexImg(index){
        this.initIndexImg = parseInt(index)
        console.log(this.initIndexImg)
        this.$refs.carousel.setActiveItem(index)
    },
    // handleScroll() {
    //   const scrollTop = this.$refs.picBox.scrollTop;
    //
    // },
  },
};
