import axios from "axios";
import i18n from "@/i18n";
import { Message } from "element-ui";
import router from "@/router/index.js";

//获取下载代码
export function getDownCartCode(userId) {
  return axios.get(`/w1/cart/export`);
}

//统计购物车数据
export function SsCart(userId) {
  return axios.get(`/w1/cart/sc/${userId}`);
}

//封装一个全局调用的【加入购物车的方法】
//goodsCode 产品编码
//num 产品数量
//spc 规格：不传默认为0，即为使用商品自带的规格
//toCurl 地址，成功之后跳转的地址
export function AddToCartByGoods(goodsCode, num, spc, toCurl) {
  return AddToCarts(goodsCode, num, spc)
    .then(() => {
      // if (i18n.locale == "en") {
      // 	Message.success("Operation succeeded");
      // } else {
      // 	Message.success("操作成功");
      // }
      if (toCurl) {
        router.push({
          path: toCurl,
        });
      }
    })
    .catch((err) => {
      if (err.response) {
        let resData = err.response.data;
        switch (resData.message) {
          case "超过库存数量":
            if (i18n.locale !== "zh-CN") {
              Message.error("Insufficient product inventory.");
            } else {
              Message.error("超过库存数量.");
            }
            break;
        }
      }
    });
}

//加入购物车
//goodsCode 产品编码
//num 产品数量
//spc 规格：不传默认为0，即为使用商品自带的规格
export function AddToCarts(goodsCode, num, spc) {
  spc = parseInt(spc);
  let speNum = spc ? spc : 0;
  let postData = {
    item_no: goodsCode,
    number: num,
    spe: speNum,
  };
  return axios.post(`/w1/cart`, postData);
  // .then((res) => {
  // 	if (i18n.locale == "en") {
  // 		Message.success("Operation succeeded");
  // 	} else {
  // 		Message.success("操作成功");
  // 	}
  // 	if (toCurl) {
  // 		router.push({
  // 			path: toCurl,
  // 		});
  // 	}
  // });
  // .catch((err) => {
  // 	if(err.response){
  // 		let resData = err.response.data;
  // 		switch (resData.message) {
  // 			case "超过库存数量":
  // 				if (i18n.locale == "en") {
  // 					Message.error("Insufficient product inventory.");
  // 				} else {
  // 					Message.error("超过库存数量.");
  // 				}
  // 				break;
  // 		}
  // 	}
  // });
}

//获取购物车列表
export function GetCartList(queryData) {
  return axios.get(`/w1/cart`, {
    params: queryData,
  });
}

//修改购物车商品数量
export function UpdateGoodsNum(goodsCode, num) {
  let updateData = {
    item_no: goodsCode,
    number: num,
  };
  return axios.put(`/w1/cart`, updateData);
}

//删除订单
export function DeleteGoods2Carts(goodsCode) {
  return axios.delete(`/w1/cart/${goodsCode}`);
}

//清空购物车
export function ClearCart() {
  return axios.delete(`/w1/cart/clear`);
}

//生成订单
export function CreateOrder(postData) {
  return axios.post(`/w1/cart/selGoodsToOrder`, postData);
}

//根据sku获取购物车的详情信息
export function GetCartInforBySku(sku) {
  return axios.get(`/w1/cart/getInfo/${sku}`);
}
