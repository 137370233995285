import axios from "axios";

//根据产品编码推荐同类型的产品
export function GetGoodsExtendInfo(sku) {
  return axios.get(`/w1/goods/goodsExtend/${sku}`);
}

//根据产品编码推荐同类型的产品
export function getGoodsRelatedBySku(code) {
  return axios.get(`/w1/goods/goodsRelated/${code}`);
}

//根据产品编码推荐同类型的产品
export function GetReGoodsByCode(code, num) {
  return axios.get(`/w1/goods/re/${code}`, {
    params: {
      size: num,
    },
  });
}

//获取有效折扣活动列表
export function getDisList() {
  return axios.get(`/w1/discount`);
}

//获取折扣商品(带翻页)
export function GetDisGoodsList(queryData) {
  return axios.get(`/w1/goods/dis/list`, {
    params: queryData,
  });
}

// //GetAllDisGoodsList 获取全部有效的折扣商品
// export function GetAllDisGoodsList(){
// 	return axios.get(`/w1/discount/list/all`);
// }

//获取热门产品
export function GoodsGetHot(quantity) {
  return axios.get(`/w1/goods/getHot/${quantity}`);
}

//获取某类型产品
export function GetTypeGoods(type, quantity) {
  return axios.get(`/w1/goods/${type}/${quantity}`);
}

//获取产品详情
export function GetGoodsInfo(code) {
  return axios.get(`/w1/goods/info/${code}`);
}

//获取产品列表
/*
queryData:{
	"GtClsNo": "30418",类型ID
	"InLang": "cn",是否国内 all:所有 cn:国内 en:国际
	"ItemNo": "123456", 条形编码
	"Name": "Working12",名字
	"Display": "n", y在线，n下线 , all 全部
	"Sw": "50", {50}库存预警数  不填则正常获取数据
	"page": "1", 当前页码
	"pageSize": "20",每页页码显示数量
	"isNew": "yes", 是否是新品  ：yes 是，no不是
	"priceBegin": "1", 价格区间 开始
	"priceEnd": "60"价格区间 结束
}
*/
export function GetGoodsList(queryData) {
  return axios.get(`/w1/goods/getList`, {
    params: queryData,
  });
}

//获取IP类型
export function getIpType() {
  return axios.get(`/w1/series/get`);
}

//获取产品图片
export function GetAllSmallImg(id) {
  return axios.get(`/w1/goods/getAllSmallImg/${id}`);
}
