import axios from "axios";

//添加收藏
export function addColect(postData) {
	return axios.post(`/w1/collect/add`, postData);
}

//是否已经加入到购物车
export function hasJoinCollect(sku) {
	return axios.get(`/w1/collect/getRow/${sku}`)
}

//getListData 获取列表数据
export function getListData(queryData) {
	return axios.get("/w1/collect/getList", {
		params: queryData
	})
}


//delCollect 删除收藏
export function delCollect(sku) {
	return axios.delete(`/w1/collect/delRow/${sku}`)
}


//获取到当前登录用户的收藏总数
export function CountNumByUser() {
	return axios.get(`/w1/collect/getCount`)
}